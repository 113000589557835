<template>
  <div>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      taggable
      :placeholder="T('Web.Generic.Locations.ChooseLocations')"
      :options="locations"
      label="name"
      @option:selected="handleInput"
      @option:deselected="handleInput"
      v-model="content"
      :disabled="false"
      :multiple="true"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  props: {
    value: { required: true },
    options: { required: true },
    selectedId: { required: false },
    selectedIds: { required: false },
  },
  components: {
    vSelect,
  },
  created() {
    if (this.selectedId != undefined) {
      let elm = this.locations.find((e) => e.id == this.selectedId);
      if (elm == undefined) return;
      this.setContent(elm);
    } else if (this.selectedIds != undefined) {
      let elm = this.locations.filter((e) => this.selectedIds.includes(e.id));
      if (elm == undefined) return;
      this.setContent(elm);
    }
  },
  data() {
    return {
      content: this.value,
      locations: this.options,
    };
  },
  mounted() {},

  computed: {},

  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    async handleLocationCreation(location) {
      if (typeof location === "object") location = location.name;
      const result = await this.$refs.locationCreateModal.open(location);
      if (!result) return (this.content = null && this.handleInput());
      this.content = this.locations.find((l) => l.id == result.id);
      this.handleInput();
    },
    setContent(value) {
      this.content = value;
    },
  },
  watch: {
    locations(newValue, oldValue) {
      if (this.selectedId != undefined) {
        let elm = this.locations.find((e) => e.id == this.selectedId);
        if (elm == undefined) return;
        this.setContent(elm);
      } else if (this.selectedIds != undefined) {
        let elm = this.locations.filter((e) => this.selectedIds.includes(e.id));
        if (elm == undefined) return;
        this.setContent(elm);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 