<template>
  <dragablemodal
    :name="'inviteusermodal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <header>{{ T("Web.DirectoryUserPage.InviteUserPopup.Title", "Invite user") }}</header>
    <div class="modals-content">
      <b-card>
        <b-row>
          <select class="form-control mb-1" v-model="selectedConcern">
            <option value="null">{{ T("Web.Generic.SelectConcern", "Select concern") }}</option>
            <option
              v-for="concerns in activeConcerns"
              :key="concerns.id"
              :value="concerns"
            >
              {{ concerns.name }}
            </option>
          </select>
        </b-row>
        <b-row v-if="selectedConcern != null">
          <input
            :placeholder="T('Web.Generic.Email')"
            type="text"
            class="form-control mb-1"
            v-model="userInvite.email"
          />
          <select class="form-control mb-1" v-model="userInvite.role">
            <option v-for="userRole in userRoles" :key="userRole">
              {{ userRole }}
            </option>
          </select>
          <div class="mb-1" style="width: 100%">
            <super-admin-location-picker
              :value="undefined"
              :options="locationOnConcern(selectedConcern.id)"
              @input="locationSelected($event)"
              v-if="userInvite.role == 'User' || userInvite.role == 'Supplier'"
            />

            <input
              v-else
              :placeholder="T('Web.Generic.Locations.AllLocations')"
              type="text"
              class="form-control mb-1"
              disabled
            />
          </div>
        </b-row>
      </b-card>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          @click="handleSubmit"
          :disabled="!canSubmit"
        >
          {{ T("Web.Generic.Send", "Send") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import {
  BModal,
  BButton,
  BAvatar,
  BTable,
  BFormTextarea,
  BCard,
  BRow,
} from "bootstrap-vue";
import { VBTooltip } from "bootstrap-vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";
import SuperAdminLocationPicker from "./SuperAdminLocationPicker.vue";
import authHelper from "@/auth";
import { mapActions } from "vuex";

export default {
  components: {
    BModal,
    BButton,
    BAvatar,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    VBTooltip,
    dragablemodal,
    SuperAdminLocationPicker,
  },
  props: ["concerns"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {},
  data() {
    return {
      userInvite: {
        email: null,
        name: null,
        role: "User",
        locations: [],
        connectTo: null,
      },
      selectedConcern: null,
      loading: false,
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    ...mapActions({
      invite: "accountUsers/inviceUser",
    }),
    locationOnConcern(concernId) {
      let concern = this.concerns.find((c) => c.id == concernId);
    console.log(concern);
      return concern.locations;
    },
    locationSelected(event) {
      let newLocationsId = [];
      event.forEach((element) => {
        newLocationsId.push(element.id);
      });
      this.userInvite.locations = newLocationsId;
    },
    async open() {
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.userInvite = {
        email: null,
        name: null,
        role: "User",
        locations: [],
        connectTo: null,
      };
      this.$refs.dragablemodal.hide();
    },
    async handleSubmit() {
      if (
        this.userInvite.role == "Admin" ||
        this.userInvite.role == "SystemAdmin"
      ) {
        this.userInvite.locations = [];
      }
      this.userInvite.connectTo = this.selectedConcern.id;
      await this.invite(this.userInvite);
      this.resolvePromise(true);
      this.handleModalHidden();
    },
  },
  computed: {
    activeConcerns() {
      return this.concerns;
    },
    canSubmit() {
      if (
        this.selectedConcern == null ||
        this.userInvite.email == "" ||
        this.userInvite.email == null
      )
        return false;

      if (
        (this.userInvite.role == "User" ||
          this.userInvite.role == "Supplier") &&
        this.userInvite.locations.length == 0
      )
        return false;

      return true;
    },
    userRoles() {
      let roles = [];
      for (const [key, value] of Object.entries(authHelper.getRoles())) {
        roles.push(value);
      }
      if (authHelper.isSuperAdmin()) {
        for (const [key, value] of Object.entries(
          authHelper.AssigableRolesForSuperAdmin()
        )) {
          roles.push(value);
        }
      }

      return roles;
    },
  },
};
</script>
