<template>
  <div>
    <b-card class="d-flex justify-content-center flex-column">
      <b-row class="topbar">
        <b-col cols="12">
          <b-button @click="openInviteUserModel" variant="primary">
            {{ T("Web.Generic.Users.InviteNewUser", "Invite User") }}
          </b-button>
          <loader class="loader" ref="loader"></loader>
        </b-col>
      </b-row>
      <b-row
        v-for="employee in employees"
        :key="employee.id"
        class="rowitem offset-top"
      >
        <b-col cols="2">
          <label> {{ T("Web.Generic.Email", "Email") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="employee.email"
            disabled
          />
        </b-col>
        <b-col cols="2">
          <select
            @change="addConcernToEmployee($event, employee)"
            class="form-control"
          >
            <option value="" selected>
              {{ T("Web.Generic.SelectConcern", "Switch account") }}
            </option>
            <option
              v-for="concern in getAvailableConcerns(employee)"
              :key="concern.id"
              :value="concern.id"
            >
              {{ concern.name }}
            </option>
          </select>
        </b-col>
        <div class="action_container">
          <b-button
            v-if="!employee.hasPassword"
            @click="reinvite(employee.email)"
            class="mr-2"
          >
            {{ T("Web.Generic.Users.ReInvite", "Reinvite") }}
          </b-button>
          <b-button
            @click="toggleVisable(employee)"
            variant="primary"
            class="mr-2"
          >
            {{ T("Web.Generic.Edit") }}
          </b-button>
          <b-button @click="remove(employee)" variant="danger" class="mr-2">
            {{ T("Web.Generic.Remove") }}
          </b-button>
        </div>
        <b-col
          class="p-2 border-bottom employee_concerns"
          cols="12"
          v-if="visable.indexOf(employee.id) > -1"
        >
          <b-row
            v-for="employeeConcern in employee.concerns.filter(
              (c) => c.id != accountId
            )"
            :key="employeeConcern.id"
          >
            <b-col cols="2">
              <label> {{ T("Web.Generic.Concern", "Concern") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="employeeConcern.name"
                disabled
              />
            </b-col>
            <b-col cols="2">
              <select
                @change="update(employee, employeeConcern)"
                class="form-control"
                v-model="employeeConcern.role"
              >
                <option v-for="userRole in userRoles" :key="userRole">
                  {{ userRole }}
                </option>
              </select>
            </b-col>
            <b-col cols="2">
              <super-admin-location-picker
                :value="undefined"
                :options="locationOnConcern(employeeConcern.id)"
                :selectedIds="employee.locationIds"
                @input="locationSelected($event, employee, employeeConcern)"
                v-if="
                  employeeConcern.role == 'User' ||
                  employeeConcern.role == 'Supplier'
                "
              />
              <input
                v-else
                :placeholder="T('Web.Generic.Locations.AllLocations')"
                type="text"
                class="form-control"
                disabled
              />
            </b-col>
            <b-button
              @click="removeFromConcern(employee, employeeConcern.id)"
              variant="danger"
              class="mr-2"
            >
              {{ T("Web.Generic.Removebtn", "Remove") }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <super-admin-invite-user-model :concerns="concerns" ref="inviteUserModel">
    </super-admin-invite-user-model>
  </div>
</template>
<script>
import loader from "@/components/layout/loader.vue";
import authHelper from "@/auth";
import ResourceService from "@/services/base/resource.service";
import SuperAdminInviteUserModel from "@/views/superadmin/superAdminInviteUserModel.vue";
import SuperAdminLocationPicker from "./SuperAdminLocationPicker.vue";
import { mapActions } from "vuex";

import { BCard, BButton, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    loader,
    SuperAdminInviteUserModel,
    SuperAdminLocationPicker,
  },
  async created() {
    this.service = new ResourceService("directoryaccount");
    let concernsResult = await this.service.get("/concerns");
    this.concerns = concernsResult.data.data;
    await this.fetchEmployees();
  },
  data() {
    return {
      service: null,
      concerns: [],
      employees: [],
      visable: [],
    };
  },
  computed: {
    accountId() {
      return authHelper.getAccountId();
    },
    userRoles() {
      let roles = [];
      for (const [key, value] of Object.entries(authHelper.getRoles())) {
        roles.push(value);
      }
      for (const [key, value] of Object.entries(
        authHelper.AssigableRolesForSuperAdmin()
      )) {
        roles.push(value);
      }
      return roles;
    },
  },
  methods: {
    ...mapActions({
      inviteUser: "accountUsers/reinviteUser",
    }),
    toggleVisable(employee) {
      let index = this.visable.indexOf(employee.id);
      if (index === -1) {
        this.visable.push(employee.id);
      } else {
        this.visable.splice(index, 1);
      }
    },
    getAvailableConcerns(employee) {
      let result = [];
      this.concerns.forEach((concern) => {
        let found = employee.concerns.find((c) => c.id == concern.id);
        if (found) return;

        result.push(concern);
      });

      return result;
    },
    async addConcernToEmployee(event, employee) {
      if (!event.target.value) return;
      this.$refs.loader.loading();
      await this.service.post(
        `employee/${employee.id}/concern/${event.target.value}/connect`,
        {}
      );
      this.$refs.loader.loadCompelete();

      await this.fetchEmployees();
    },
    locationOnConcern(concernId) {
      let concern = this.concerns.find((c) => c.id == concernId);

      return concern.locations;
    },
    async fetchEmployees() {
      // this.$refs.loader.loading();
      let result = await this.service.get("/employees");
      this.employees = result.data.data;
      //this.$refs.loader.loadCompelete();
    },
    async openInviteUserModel() {
      let result = await this.$refs.inviteUserModel.open();
      if (result) await this.fetchEmployees();
    },
    async reinvite(email) {
      this.$refs.loader.loading();
      await this.service.post(`employee/reinvite`, { email: email });

      this.$refs.loader.loadCompelete();
    },
    async removeFromConcern(employee, concernId) {
      this.$refs.loader.loading();
      await this.service.delete(
        `employee/${employee.id}/concern/${concernId}/unconnect`
      );
      this.$refs.loader.loadCompelete();
      await this.fetchEmployees();
    },
    async remove(employee) {
      if (!confirm(this.T("Web.Generic.Users.RemoveUserConfirm", "Do you wish to remove this user?"))) return;
      this.$refs.loader.loading();
      await this.service.delete(`employee/${employee.id}`);
      this.$refs.loader.loadCompelete();
      await this.fetchEmployees();
    },
    async locationSelected(event, employee, concern) {
      let newLocationsId = [];
      event.forEach((element) => {
        newLocationsId.push(element.id);
      });

      employee.locationIds = newLocationsId;

      await this.update(employee, concern);
    },
    async update(employee, concern) {
      this.$refs.loader.loading();
      let data = {
        locationIds: employee.locationIds,
        role: concern.role,
      };
      await this.service.put(
        `/employee/${employee.id}/concern/${concern.id}`,
        data
      );
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
@media (max-width: 648px) {
  .card-body {
    overflow: auto;
  }
  .topbar {
    display: none !important;
  }
  .row {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    align-items: center !important;
    border-bottom: 2px solid #ccc;
    > div {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
    }
    button {
      margin: 0 !important;
      margin-top: 5px !important;
      padding: 0.3rem 1.5rem !important;
      width: 100%;
    }
    input,
    select {
      margin-bottom: 5px;
    }
    .employee_concerns {
      padding: 5px 10px !important;
    }
  }

  .rowitem {
    .action_container {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>